import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { ContentWrapper } from "../components/ContentWrapper"
import Layout from "../components/Layout"
import { PrimaryButton } from "../components/PrimaryButton"
import Seo from "../components/seo"

export default function Index() {
  return (
    <Layout>
      <Seo title="CONTACT COMPLETE" />
      <div className="d:hidden relative h-[200px] flex justify-center items-center">
        <StaticImage
          src="../images/nexteam-illustration.png"
          alt="Nexteam Illustration"
          className="!absolute z-0 left-0 top-0 right-0 bottom-0 object-bottom object-cover"
        />
      </div>

      <ContentWrapper className="py-[100px] m:py-[50px] px-[1rem]">
        <h1 className="text-2xl m:hidden mb-[3rem]">
          この度はnexteamにお問い合わせいただき、ありがとうございます。
        </h1>

        <h1 className="text-2xl d:hidden mb-[3rem]">
          この度はnexteamに
          <br />
          お問い合わせいただき、
          <br />
          ありがとうございます。
        </h1>

        <p>
          私たちは、日本の部活のフィジカルスタンダードを引き上げ、日本の学生スポーツひいては日本スポーツ業界の発展の一助となるべく総合ウェルネスサービスを展開しております。
          <br />
          <br />
          お問合せいただいた内容を弊社内で確認の上、別途担当者よりご連絡申し上げます。今しばらくお待ちください。
        </p>

        <Link to="/">
          <PrimaryButton className="m:w-full w-[136px] flex justify-center mt-[2rem]">
            戻る
          </PrimaryButton>
        </Link>
        <div className="mt-[2rem] text-sm text-[#BDBDBD] text-center pb-[25px]">
          Copyright ©nexteam {new Date().getFullYear()}
        </div>
      </ContentWrapper>
    </Layout>
  )
}
